<template>
  <div class="flex flex-col w-full relative -mt-2 deails pr-2">
    <data-repeater
      :columns="roleprofile.columns"
      :rows="filteredRoleProfiles"
      @selected="payload => (roleprofile = payload)"
      v-model="archived"
      :title="title"
    />
  </div>
</template>

<script>
import { getUnit } from '@/services/organization'

export default {
  name: 'unit-details',
  components: {},
  data() {
    return {
      roleprofile: {
        columns: [
          {
            label: 'Rollenprofil',
            prefix: '',
            postfix: '',
            field: ['name'],
            type: 'callback',
            headerClass: 'class-in-header second-class',
            headerAlign: 'left',
            width: '30',
            callback: item => {
              this.$router.push(`/organisation/unit/roleprofile/${item.id}`)
            },
          },
          {
            label: 'Vorgesetzte Rolle',
            prefix: '',
            postfix: '',
            field: ['supervisor.name'],
            width: '30',
          },
          {
            label: 'Stellvertretende Rolle',
            prefix: '',
            postfix: '',
            field: ['deputy.name'],
            width: '30',
          },
          {
            label: 'Arbeitspensum',
            field: ['target_sum_workload'],
            prefix: '',
            postfix: '%',
            headerAlign: 'left',
            width: '20',
          },
          {
            label: null,
            prefix: '',
            postfix: '',
            field: ['id'],
            width: '30',
            type: 'checkbox',
          },
        ],
        rows: [],
        archived: false,
      },
      rows: [],
      breadcrumbs: {},
      archived: false,
    }
  },
  created() {
    this.$bus.$off('realod-units')
    this.$bus.$on('realod-units', this.init)
  },
  methods: {
    init() {
      this.$emit('breadcrumbs', this.breadcrumbs)
      const id = this.$route.params.id

      getUnit(id).then(response => {
        this.roleprofile.rows = response.data.roleprofiles

        this.filterHighestEmployee()
      })
    },
    filterHighestEmployee() {
      this.roleprofile.rows.forEach(
        roleprofile =>
          (roleprofile.employe = roleprofile.employes
            .sort((first, current) => first.workload - current.workload)
            .reverse()[0])
      )
    },
  },
  computed: {
    filteredRoleProfiles() {
      return (
        this.roleprofile &&
        this.roleprofile.rows &&
        this.roleprofile.rows.filter(roleprofile => {
          return roleprofile.archived === this.archived
        })
      )
    },
    roleProfileActions() {
      return {
        title: this.currentNode ? 'Rollenprofil erstellen' : null,
        component: 'new-role',
      }
    },
    title() {
      return this.archived ? 'Aktivierte Rollen anzeigen' : 'Deaktivierte Rollen anzeigen'
    },
  },
  watch: {
    '$route.params.id': {
      handler: function () {
        this.init()
      },
      immediate: true,
    },
  },
}
</script>
